import React from 'react'

import {GetServerSideProps} from 'next'
import {
  QueryListenerOptions,
  SeoOrFaviconTag,
  useQuerySubscription,
} from 'react-datocms'
import {DatocmsContent} from 'src/components/content/DatocmsContent'
import WebPage, {WebPageCommonProps} from 'src/components/layout/WebPage'
import {makePageCommonProps} from 'src/utils'

import {allFrontPagesQuery} from '@festi/common/api/datocms/queries'
import {AllFrontPages} from '@festi/common/api/datocms/types/AllFrontPages'
import {getDatoFrontPages} from '@festi/common/api/global-cache'
import {onDutyFree} from '@festi/common/constants/channels'
interface Props {
  common: WebPageCommonProps
  subscription: QueryListenerOptions<AllFrontPages, {dutyFree: boolean}>
}

export const getServerSideProps: GetServerSideProps = async ({
  res,
  preview = false,
}) => {
  const [common, subscription] = await Promise.all([
    makePageCommonProps(preview),
    getDatoFrontPages(preview, allFrontPagesQuery, {
      dutyFree: onDutyFree,
    }),
  ])

  if (common.categories.length === 0) {
    res.setHeader('Cache-Control', 's-maxage=1, stale-while-revalidate')
  } else {
    res.setHeader('Cache-Control', 's-maxage=60, stale-while-revalidate=3600')
  }

  return {
    props: {
      subscription,
      common,
      preview,
    },
  }
}

export default function Front({subscription, common}: Props): JSX.Element {
  const {data} = useQuerySubscription<AllFrontPages>(subscription)

  // First front page is the most recently published
  const frontPage = data?.allFrontPages[0]

  return (
    <WebPage
      common={common}
      datoMeta={frontPage?.seo.concat(data?.site.favicon) as SeoOrFaviconTag[]}
      seoIndexing={frontPage?.seoIndexing}
    >
      <DatocmsContent content={frontPage?.content} />
    </WebPage>
  )
}
