import {Page_page_content} from '@festi/common/api/datocms/types/Page'

import {
  CardImageBlock,
  IconCardImageBlock,
  TextBlock,
  AccordionList,
  BulletsBlock,
  InfoList,
  LocationList,
  ProductSlider,
  PageHeader,
  PageBanner,
  HtmlBlock,
  ContentBlock,
  VideoBlock,
  ImageBlock,
  InfoBulletList,
  CategorySlider,
  VideoBannerBlock,
  ImageCardList,
  BambuserCta,
  EmployeeList,
  ProductList,
  GridBlock,
  CarouselBlock,
  CategotyCarousel,
  WishlistSlider,
  ProductCarousel,
} from '../pageBlocks'

export type Content = Page_page_content & {
  id: string
  slug: string | null
}

type EntryType = Content['__typename']

type ContentMap = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in EntryType]?: any
}

export const contentMap: ContentMap = {
  TextBlockRecord: TextBlock,
  CardImageBlockRecord: CardImageBlock,
  IconCardImageBlockRecord: IconCardImageBlock,
  LocationListRecord: LocationList,
  AccordionListRecord: AccordionList,
  BulletListRecord: BulletsBlock,
  ProductSliderRecord: ProductSlider,
  InfoCardListRecord: InfoList,
  ContentBlockRecord: ContentBlock,
  VideoBlockRecord: VideoBlock,
  ImageBlockRecord: ImageBlock,
  PageHeaderRecord: PageHeader,
  PageBannerRecord: PageBanner,
  HtmlBlockRecord: HtmlBlock,
  InfoBulletListRecord: InfoBulletList,
  VideoBannerBlockRecord: VideoBannerBlock,
  CategorySliderRecord: CategorySlider,
  ImageCardListRecord: ImageCardList,
  BambuserCtaRecord: BambuserCta,
  EmployeeListRecord: EmployeeList,
  ProductListRecord: ProductList,
  GridSystemRecord: GridBlock,
  CarouselBlockRecord: CarouselBlock,
  CategoryCarouselRecord: CategotyCarousel,
  WishlistSliderRecord: WishlistSlider,
  ProductCarouselRecord: ProductCarousel,
}
