import React from 'react'

import {Page_page_content} from '@festi/common/api/datocms/types/Page'

import {BlockWrapperContext} from '../layout/BlockWrapper'
import {Content, contentMap} from './content-map'

interface Props {
  content: (Page_page_content | Content | null)[] | null
}

export function DatocmsContent({content}: Props): JSX.Element {
  if (!content) {
    return null
  }
  const entries: Content[] = content.filter(
    (entry) => entry != null,
  ) as Content[]

  return (
    <>
      {entries?.map((entry: Content) => {
        if (!contentMap) return null
        const Component = contentMap?.[entry?.__typename]
        return Component ? (
          <BlockWrapperContext.Provider
            key={entry.id}
            value={{slug: entry.slug}}
          >
            <Component {...entry} />
          </BlockWrapperContext.Provider>
        ) : null
      })}
    </>
  )
}
